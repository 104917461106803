@use "sass:map";

@function get_theme_color($theme, $light, $dark) {
  $result: $light;
  @if map.get($theme, 'is-dark') {
      $result: $dark;
  }
  @return $result;
}

@mixin view-birthday-component-theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);

  .odd_even_items:nth-child(2n+1)
  {
    background: get_theme_color($theme, #eee, #383838);
  }

  .due-date-today {
    background: get_theme_color($theme, #d1e8db, map.get($primary, 900)) !important;
  }

  .due-date-tomorrow {
    background: get_theme_color($theme, #e6e8d1, map.get($accent, 900)) !important;
  }
}
